<div class="bg-white full-height">
	<div class="card-body">
		<div style="text-align: center;margin-top: 3rem;margin-bottom: 3.5rem;">
		</div>
		<p class="login-box-msg">Change Password</p>
		<p class="login-box-msg-thin">Enter old password to continue</p>
		<form [formGroup]="recoverPasswordForm" (ngSubmit)="recoverPassword()" autocomplete="new-password">
			<div class="input-group input-border mb-3">
				<div class="input-group-append">
					<div class="input-group-text">
						<i-feather name="lock"></i-feather>
					</div>
				</div>
				<input formControlName="oldPassword" type="password" class="form-control" placeholder="Old Password" autocomplete="new-password"/>
			</div>
			<div class="input-group input-border mb-3">
				<div class="input-group-append">
					<div class="input-group-text">
						<i-feather name="lock"></i-feather>
					</div>
				</div>
				<input formControlName="newPassword" type="password" class="form-control" placeholder="New Password" autocomplete="new-password"/>
			</div>
			<div class="input-group input-border mb-3">
				<div class="input-group-append">
					<div class="input-group-text">
						<i-feather name="shield"></i-feather>
					</div>
				</div>
				<input formControlName="confirmPassword" type="password" class="form-control" placeholder="Confirm Password" autocomplete="new-password"/>
			</div>
			<div class="row med-font mt-3">
				<div class="col-12 mt-3">
					<button class="btn btn-primary btn-block" type="submit" [disabled]="isAuthLoading" value="submit">Submit &nbsp;<i *ngIf="isAuthLoading" class="fa fa-sync fa-spin"></i></button>
				</div>
			</div>
		</form>
	</div>
</div>