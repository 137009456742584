import {Injectable} from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import {Observable} from 'rxjs';
import { CommonFunctionService } from '@services/common-function.service';
import { jwtDecode } from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private utilities : CommonFunctionService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if(this.getProfile()){
            return true;
        }
        else{
            this.utilities.clearLocalVars();
            this.utilities.toastMsg('warning','Invalid access detected','Sign In Again !');
            location.replace("/login");
        }
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(next, state);
    }

    // getProfile() {
    //     if (localStorage.getItem('personalDetails')) {
    //         const personalDetails = localStorage.getItem('personalDetails');
    //         // const logintoken = localStorage.getItem('LoginToken');
    //         // return !!personalDetails && !!logintoken;
    //         return true
    //     }
    //     else{
    //         return false;
    //     }
    // }

    getProfile() {
        const personalDetails = localStorage.getItem('personalDetails');
        if (personalDetails) {
          const { LoginToken } = JSON.parse(personalDetails);
          if (LoginToken) {
            const decodedToken = jwtDecode(LoginToken);
            const currentTime = Date.now() / 1000;
            
            if (decodedToken.exp && currentTime < decodedToken.exp) {
              return true;
            } else {
              this.router.navigate(['/login']);
              return false;
            }
          } else {
            alert('Please Login');
            this.router.navigate(['/login']);
            return false;
          }
        } else {
          alert('Please Login');
          this.router.navigate(['/login']);
          return false;
        }
      }
}
