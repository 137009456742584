import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleauthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.validateAccess(state.url);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.validateAccess(state.url);
  }

  private validateAccess(url: string): boolean | UrlTree {
    const adminMenu = localStorage.getItem('adminMenu');

    if (!adminMenu) {
      console.error('adminMenu is missing in localStorage');
      return this.router.createUrlTree(['/login']); // Redirect to login if adminMenu is missing
    }

    let menuList: any[];
    try {
      menuList = JSON.parse(adminMenu);
    } catch (error) {
      console.error('Invalid JSON in adminMenu', error);
      return this.router.createUrlTree(['/login']);
    }

    if (this.isLinkPresentInMenu(url, menuList)) {
      return true; // Allow access if URL exists in the adminMenu
    } else {
      console.warn(`Access denied to ${url}. Redirecting to a fallback page.`);
      return this.router.createUrlTree([this.getFallbackUrl(menuList)]);
    }
  }

  private isLinkPresentInMenu(link: string, menuList: any[]): boolean {
    // Normalize the link by removing the leading slash if present
    const normalizedLink = link.startsWith('/') ? link.slice(1) : link;

    for (const menu of menuList) {
      if (normalizedLink === menu.AppUrl) {
        return true;
      }

      if (menu.ChildMenu && Array.isArray(menu.ChildMenu)) {
        if (this.isLinkPresentInMenu(normalizedLink, menu.ChildMenu)) {
          return true;
        }
      }
    }
    return false;
  }

  private getFallbackUrl(menuList: any[]): string {
    // Find the first valid URL in the adminMenu
    for (const menu of menuList) {
      if (menu.AppUrl) {
        return `/${menu.AppUrl}`; // Prefix with a slash
      }

      if (menu.ChildMenu && Array.isArray(menu.ChildMenu)) {
        const fallbackChildUrl = this.getFallbackUrl(menu.ChildMenu);
        if (fallbackChildUrl) {
          return `/${fallbackChildUrl}`;
        }
      }
    }
    return '/'; // Default fallback if no valid URL is found
  }
}


















// import { Injectable } from '@angular/core';
// import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree, Router } from '@angular/router';
// import { Observable } from 'rxjs';

// @Injectable({
//   providedIn: 'root'
// })
// export class RoleauthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
//   constructor(private router: Router) {}
//   canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     return true;
//   }
  
//   canActivateChild( childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     let userData=JSON.parse(localStorage.getItem('adminMenu'));
//     if (this.isLinkPresentInMenu(state.url, userData)) {
//       return true;
//     }
//     else{
//       if(userData[0].AppUrl!='#')
//       {
//         this.router.navigate([(userData[0].AppUrl).toLowerCase()]);
//       }
//       else
//       {
//         this.router.navigate([(userData[0].ChildMenu[0].AppUrl).toLowerCase()]);
//       }
//     }
//   }
  
//   isLinkPresentInMenu(link: string, menuList: any[]): boolean {
//     for (let i = 0; i < menuList.length; i++) {
//       if (link.includes(menuList[i]['AppUrl'])) {
//         return true;
//       }
//       if (menuList[i]['ChildMenu'] != null) {
//         if (this.isLinkPresentInMenu(link, menuList[i]['ChildMenu'])) {
//           return true;
//         }
//       }
//     }
//     return false;
//   }
  
  
//   canDeactivate( component: unknown, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     return true;
//   }
  
//   canLoad( route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     return true;
//   }
// }