import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@common/main/main.component';
import {LoginComponent} from '@auth/login/login.component';
import {RegisterComponent} from '@auth/register/register.component';
import {AuthGuard} from '@guards/auth.guard';
import {RoleauthGuard} from '@guards/role-auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@auth/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@auth/recover-password/recover-password.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'usersetup',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/user-setup/user-setup.module').then(m => m.UserSetupModule)
            },
            {
                path: 'walletmanagement',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/user-balance/user-balance.module').then(m => m.UserBalanceModule)
            },
            {
                path: 'profile',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/profile/profile.module').then(m => m.ProfileModule)
            },
            {
                path: 'report',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/all-reports/all-reports.module').then(m => m.AllReportsModule)
            },
            {
                path: 'ordermanagement',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/order-management/order-management.module').then(m => m.OrderManagementModule)
            },
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'change-password',
        component: RecoverPasswordComponent,
        canActivate: [AuthGuard]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
