export const config: any = {
   "emailAuthApi" : "LoginAuth",
   "loginApi" : "login/",
   // "getUserSite" : "getUserSite",
   "getUserSite" : "getUserSiteV1",
   // "getDashboardCount" : "dashboard/getDashboardCount",
   "getDashboardCount" : "dashboard/getDashboardCountV1",
   // "getDashboardDepositChart" : "dashboard/getDashboardDepositChart",
   "getDashboardDepositChart" : "dashboard/getDashboardDepositChartV1",
   //category service,
   "categories":"categories/",
   //product service,
   "products":"products/",
   // "getAllPlayer" : "user/getAllPlayer",
   "getAllPlayer" : "user/getAllPlayerV1",
   // "getAllPlayerForEdit":"user/GetAllPlayerForEdit",
   "getAllPlayerForEdit":"user/GetAllPlayerForEditV1",
   // "getUserData" : "user/getUserData",
   "getUserData" : "user/getUserDataV1",
   "editPlayerDetails":"user/EditPlayerDetails",
   "hasDepositAccess" : "user/HasDepositAccess",
   "saveIMPSAccess" : "user/SaveIMPSAccess",
   // "getUserStatement" : "user/getUserStatement",
   "getUserStatement" : "user/getUserStatementV1",
   // "getUserDepositeData" : "user/getUserDepositeData",
   "getUserDepositeData" : "user/GetUserDepositeDataV1",
   // "withdrawalData" : "user/WithdrawalData",
   "withdrawalData" : "user/WithdrawalDataV1",
   // "getUserWithdrawal" : "user/getUserWithdrawData",
   "getUserWithdrawal" : "user/GetUserWithdrawDataV1",
   // "exportStatement" : "user/ExportStatement",
   "exportStatement" : "user/ExportStatementV1",
   "processWithdrawal" : "user/ProcessWithdrawal",
   "transferFundViaPayconnect" : "user/TransferFundViaPayconnect",
   // "exportDepositAndWithdrawal" : "user/ExportDepositAndWithdrawal",
   "exportDepositAndWithdrawal" : "user/ExportDepositAndWithdrawalV1",
   "approveWithdrawal" : "user/ApproveWithdrawal",
   // "downloadUserWithdrawData" : "dashboard/DownloadUserWithdrawData",
   "downloadUserWithdrawData" : "dashboard/DownloadUserWithdrawDataV1",
   // "downloadUserDepositData":"dashboard/DownloadUserDepositData",
   "downloadUserDepositData":"dashboard/DownloadUserDepositDataV1",
   // "downloadUserBonusDepositData":"dashboard/DownloadUserBonusDepositData",
   "downloadUserBonusDepositData":"dashboard/DownloadUserBonusDepositDataV1",
   "getAllAdmins" : "user/GetAllAdmins",
   "getAdminRoles" : "user/GetAdminRolesData",
   // "getReportRegisterUser" : "report/GetReportRegisterUser",
   "getReportRegisterUser" : "report/GetReportRegisterUserV1",
   // "downloadAllReportData" : "report/DownloadAllReportData",
   "downloadAllReportData" : "report/DownloadAllReportDataV1",
   "saveRoleChange" : "user/SaveRoleChange",
   "getAdminMenuMappingsData" : "GetAdminMenuMappingsData",
   "saveBlockAdmin" : "user/SaveBlockAdmin",
   "saveDepositAccess" : "user/SaveDepositAccess",
   "saveAdminProfile" : "user/SaveAdminProfile",
   // "saveUserProfile" : "user/SaveUserProfile",
   "saveUserProfile": "user/SaveUserProfileV1",
   // "updateUserAccount":"user/UpdateUserAccount",
   "updateUserAccount":"user/UpdateUserAccountV1",
   "changeUserStatus":"user/ChangeUserStatus",
   // "getPGMaster" : "bank/getPaymentGatewayMasterDetails",
   "getPGMaster" : "bank/getPaymentGatewayMasterDetailsV1",
   "getDepoPromo" : "user/GetDepositPromotionList",
   "changeDepoPromoStat" : "user/UpdateDepositPromotionStatus",
   "changePaymentGatewayStatus" : "bank/ChangePaymentGatewayStatus",
   // "getUserDepositList" : "report/getUserDepositList",
   "getUserDepositList" : "report/getUserDepositListV1",
   // "downloadUserDepositList" : "report/DownloadUserDepositList",
   "downloadUserDepositList" : "report/DownloadUserDepositListV1",
   // "getCallBackRequest" : "user/GetCallBackRequest",
   "getCallBackRequest" : "user/GetCallBackRequestV1",
   "saveCallRequest" : "user/SaveCallRequest",
   // "getCallLog" : "user/getCallLog",
   "getCallLog" : "user/getCallLogV1",
   // "getGamePlayed" : "user/getGamePlayed",
   "getGamePlayed" : "user/getGamePlayedV1",
   // "getOnlineDepositList" : "user/getOnlineDepositList",
   "getOnlineDepositList" : "user/getOnlineDepositListV1",
   "getCreatorPages" : "getCreatorPages",
   "getDCPromotionbyPage" : "getDCPromotionViaPageid",
   "saveCreatorPage" : "saveCreatorPage",
   "changeCreatorPageStatus" : "ChangeCreatorPageStatus",
   "getCreatorStatements" : "getDCPaymentStatement",
   "getDCPromotionList" : "getDCPromotionList",
   "saveDCPaymentStatement" : "saveDCPaymentStatement",
   "getDCPromotion" : "getDCPromotion",
   "getDCPageList" : "getDCPageList",
   "saveDCPromotion" : "saveDCPromotion",
   "getDCViewHistoryViaId" : "getDCViewHistoryViaId",
   "changeDCPromotionStatus":"ChangeDCPromotionStatus",
   "changePassword" : "ChangePassword",
   // "exportWithdrawalData":"user/ExportWithdrawalData",
   "exportWithdrawalData":"user/ExportWithdrawalDataV1",
   "playerResetPassword":"user/PlayerResetPassword",
   "smsProvider":"user/GetSMSOTPServiceProviderList",
   "saveProvider":"user/SaveSMSOTPServiceProvider",
   "multiLanguage":"user/GetMultiLanguages",
   "saveLan":"user/SaveLanguage",
   "updateLan":"user/UpdateSMSProviderDetails",
   "getallotpList":"user/GetAllSMSOTPList"
}