import {AppState} from '@/store/state';
import {ToggleControlSidebar, ToggleSidebarMenu} from '@/store/ui/actions';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';
import {ApiService} from '@services/api.service';
import {Router} from '@angular/router';

const BASE_CLASSES = 'main-header navbar navbar-expand';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
	@HostBinding('class') classes: string = BASE_CLASSES;
	public ui: Observable<UiState>;
	// public userSites:any=[];
	// public SelectedSite=new FormControl();
	// public userWals:any=[];
	// public SelectedSiteWal=new FormControl();
	urlPath = window.location.pathname;
	// showSiteOpts = !this.urlPath.includes('/users/playerdetailview');
	dkMode=localStorage.getItem('dkMode');
	
	constructor(private router: Router,private appService: AppService, private store: Store<AppState>, private apiservice: ApiService) {}
	
	ngOnInit() {
		this.ui = this.store.select('ui');
		this.ui.subscribe((state: UiState) => {
			this.classes = `${BASE_CLASSES} ${state.navbarVariant}`;
		});
		// this.userSites = JSON.parse(localStorage.getItem('userSites'));
		// this.userWals = JSON.parse(sessionStorage.getItem('WalList'));
		// let oldVal = sessionStorage.getItem('selectedSite');
		// this.SelectedSite.setValue(sessionStorage.getItem('selectedSite'));
		// this.SelectedSiteWal.setValue(sessionStorage.getItem('WalChosen'));
		// this.SelectedSite.valueChanges.subscribe(value => {
		// 	if(oldVal && value!=oldVal){
		// 		sessionStorage.setItem('selectedSite',value);
		// 		let newName = this.userSites.find((obj: { Code: any; }) => obj.Code === value);
		// 		sessionStorage.setItem('chosenSite',newName?newName.Name:'');
		// 		sessionStorage.setItem('WalList',JSON.stringify(newName.Wallets));
		// 		sessionStorage.setItem('WalChosen',newName?newName.Wallets[0].Id:'');
		// 		sessionStorage.setItem('WalName',newName?newName.Wallets[0].Name+' - '+newName.Wallets[0].Code:'');
		// 		window.location.reload();
		// 	}
		// });
	}
	
	logout() {
		this.appService.logout();
		// this.router.navigate(['/login']);
		location.replace("/login");

	}
	
	onToggleMenuSidebar() {
		this.store.dispatch(new ToggleSidebarMenu());
	}
	
	onToggleControlSidebar() {
		this.store.dispatch(new ToggleControlSidebar());
	}

	switchMode(tVal:string){
		localStorage.setItem('dkMode',tVal);
		var event = new CustomEvent('modeWeb', { detail: { key: 'dkMode', value: 'tVal' } });
    window.dispatchEvent(event);
	}
}